exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-draft-js": () => import("./../../../src/pages/draft.js" /* webpackChunkName: "component---src-pages-draft-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mp-js": () => import("./../../../src/pages/mp.js" /* webpackChunkName: "component---src-pages-mp-js" */),
  "component---src-pages-page-2-tsx": () => import("./../../../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-pro-js": () => import("./../../../src/pages/pro.js" /* webpackChunkName: "component---src-pages-pro-js" */),
  "component---src-templates-doc-js": () => import("./../../../src/templates/doc.js" /* webpackChunkName: "component---src-templates-doc-js" */)
}

