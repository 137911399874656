import React from "react"
import PropTypes from "prop-types"

const FullScreenVideo = ({close}) => {
    return <div className="fixed z-50 inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
        <div>
            <iframe title="ACF to Custom Database Tables overview" style={ {width: '70vw', height: '40vw'} } src="https://www.youtube.com/embed/ggrKQmUoZ-0?autoplay=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

            <div role="button" className="text-white fixed top-0 right-0 p-10 cursor-pointer" onClick={()=> {close(false)}}>
                <svg className="fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path></svg>
            </div>

        </div>
    </div>
}

FullScreenVideo.propTypes = {
    close: PropTypes.func
}

export default FullScreenVideo
