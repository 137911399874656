import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/acf-ct-icon.png"

const Header = ({ siteTitle, toggleSideBar, sideBarState }) => {

    let hamburgerIcon = <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path></svg>;

    if(sideBarState === true) {
        hamburgerIcon = <svg className="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path></svg>;
    }

    return <header className="border-b border-gray-200 mb-5 fixed top-0 inset-x-0 z-30 bg-white">
        <div className="container relative mx-auto h-16 flex items-center px-4 md:px-6">
            <div className="absolute left-0 pl-3 md:hidden text-gray-600" onClick={toggleSideBar} role = "button">
                {hamburgerIcon}
            </div>
            <Link to="/" className="hover:text-primary-green w-3/5 md:w-2/5 text-lg md:text-xl font-bold pl-6 md:pl-0 flex items-center space-x-2">
                <img src={Logo} alt={siteTitle} className="w-10"/>
                <h2 className="hidden lg:inline-block">{siteTitle}</h2>
            </Link>
            <div className="w-2/5 md:w-3/5 text-right">
                <Link to="/" className="ml-2 py-2 px-4 md:px-6 text-sm md:text-base rounded bg-primary-green text-white">Get it now</Link>
            </div>
        </div>
    </header>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  toggleSideBar: PropTypes.func,
  sideBarState: PropTypes.bool
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
