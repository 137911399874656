
import React from "react"

import f1 from "../images/marketplace/feature-1.jpg"
import f2 from "../images/marketplace/feature-2.jpg"
import f3 from "../images/marketplace/feature-3.jpg"
import gf from "../images/marketplace/acf-ct-table-big.gif"

const Mp = () => (
  <div>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <img src={f1} />
    <img src={f2} />
    <img src={f3} />
    <img src={gf} />
  </div>
)

export default Mp
