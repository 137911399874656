import React, { useState, useMemo } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { getGumroadLink } from '../utils';

import SEO from "../components/seo"
import Footer from "../components/footer"
import Faq from "../components/faq"
import FullScreenVideo from "../components/FullScreenVideo"
import ViewLogs from "../images/lander-video-thumb.jpg"
import CreateTableVideo from "../images/demo/create-table.mp4"
import NativeMethods from "../images/demo/native-methods.png"
import Filters from "../images/demo/custom-filters.png"
import Dot from "../images/dots.inline.svg";
import HalfCircle from "../images/half-circle.inline.svg";
import Logo from "../images/acf-ct-icon.png"

const FeaturesRow = () => {
  const list = [
    {
      title: "Create Table using ACF Fields",
      desc:
        "No need to write create/update table queries. Manage table <br/>columns using ACF fields.",
      video: CreateTableVideo,
    },
    {
      title: "Custom Filters",
      desc: "Extend functionality using custom filters.",
      img: Filters,
      cta: "View Filters",
      link: "/docs/filters/",
    },
    {
      title: "Change Log",
      desc: "Get a detailed history of all table edits",
      img: ViewLogs,
    },
    {
      title: "ACF native method support",
      desc:
        "ACF's <code>get_field()</code> function can be used to retrieve data from <br/>custom tables",
      img: NativeMethods,
      cta: "View Functions",
      link: "/docs/functions/",
    },
  ]

  return list.map((details, index) => {
    const cssClasses =
      index % 2 !== 0 ? "acf-features flex-row-reverse" : "acf-features"
    const imglasses = index % 2 !== 0 ? "acf-left md:pl-12" : "acf-left md:pr-12"

    let media = details.video ? (
      <video
        src={details.video}
        className="shadow-md rounded-md"
        loop
        muted
        autoPlay
      />
    ) : (
      <img alt={details.title} src={details.img} className="shadow-md rounded" />
    )

    let cta = details.cta ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="bg-primary-green px-6 text-white text-base rounded py-3 leading-none mt-6 inline-block"
        href={details.link}
      >
        {details.cta}
      </a>
    ) : null

    return (
      <div className={cssClasses} key={index}>
        <div className={imglasses}>{media}</div>
        <div className="acf-right">
          <h3 className="title">{details.title}</h3>
          <p
            className="desc"
            dangerouslySetInnerHTML={{ __html: details.desc }}
          ></p>
          {cta}
        </div>
      </div>
    )
  })
}

const IndexPage = () => {
  const [showNavShadow, setShowNavShadow] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y < -80
      if (isShow !== showNavShadow) setShowNavShadow(isShow)
    },
    [showNavShadow],
    null,
    false,
    300
  )

  const headerClass = showNavShadow ? "shadow-md" : ""
  const headerCta = showNavShadow ? <Link to="/" className="mr-6 py-2 px-6 rounded bg-primary-green text-white leading-none hover:bg-green-light transition duration-500 ease-in-out hidden md:inline-block">Get it now</Link> : null;

  const videoPopup = showVideo ? <FullScreenVideo close={setShowVideo} /> : null;

  if (showVideo) {
    trackCustomEvent({
        category: "lander",
        action: "play_intro_video"
    })
   }

  return useMemo(
    () => (
      <div className="relative">
        <SEO title="ACF to Custom Database Tables - Store ACF fields data in a custom tables" titleOverride={true} />

        <div className="hidden lg:block absolute top-0 right-0 text-gray-200">
            <Dot/>
        </div>

        <div className="hidden lg:block absolute bottom-0 left-0 text-gray-200 transform rotate-180 mb-32">
            <HalfCircle/>
        </div>

        <div className="hidden lg:block absolute right-0 text-gray-200" style={ {top: '50%'} }>
            <HalfCircle/>
        </div>

        <header
          className={
            "border-b1 border-gray-200 mb-5 fixed top-0 inset-x-0 z-10 bg-white " +
            headerClass
          }
        >
          <div className="container mx-auto h-16 flex items-center px-6 xl:px-0">
            <Link to="/" className="hover:text-primary-green w-1/2 md:w-2/5 text-base md:text-xl font-bold flex items-center space-x-2">
              <img src={Logo} alt="ACF to Custom Database Tables" className="w-10"/>
              <h1 className="hidden lg:inline-block">ACF to Custom Database Tables</h1>
            </Link>
            <div className="md:block w-1/2 md:w-3/5 text-right">
              {headerCta}
              <Link
                to="/docs/create-custom-table/"
                className="py-3 hover:text-primary-green text-sm md:text-base"
              >
                Documentation
              </Link>
            </div>
          </div>
        </header>

        { videoPopup }

        <div className="container mx-auto px-4 xl:px-0">
          <div className="md:flex md:items-center pt-16 intro">
            <div className="md:w-1/2">
              <h2 className="font-semibold text-primary-black mb-10 pt-10 md:pt-0">
                Store your
                <br />{" "}
                <b className="text-primary-green tracking-tighter">ACF</b>{" "}
                fields data in a
                <br /> Custom Database Table
              </h2>
              <div className="space-x-2 md:space-x-4">
                <a
                    href="https://wordpress.org/plugins/acf-to-custom-database-tables/"
                    target="_blank"
                    className="cta inline-block text-lg px-4 md:px-5 py-4 bg-primary-green text-white font-bold text-center rounded leading-none hover:bg-green-light transition duration-500 ease-in-out"
                >
                    Download for free
                </a>
                <a
                    href={ getGumroadLink() }
                    className="cta inline-block text-lg px-4 md:px-6 py-4 bg-primary-blue text-white font-bold text-center rounded leading-none hover:bg-blue-400 transition duration-500 ease-in-out"
                >
                    Get Pro
                </a>
              </div>
            </div>
            <div className="md:w-1/2 py-10 md:py-24">
                <div className="relative shadow-lg cursor-pointer">
                    <img src={ViewLogs} alt="ACF to Custom Database Tables plugin overview preview" className="ml-auto rounded-lg" />
                    <div 
                    className="absolute inset-0 bg-gray-900 bg-opacity-25 hover:bg-opacity-75 flex items-center justify-center rounded-lg hero-vid-overlay" 
                    onClick={ () => {setShowVideo(true)}}
                    role="button" 
                    tabIndex="0">
                        <svg className="w-32 h-32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/></svg>
                    </div>
                </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto px-4 xl:px-0">
          <FeaturesRow />
        </div>

        <div className="mb-16 mt-16">
          <div className="container mx-auto px-4 md:px-0">
            <h3 className="text-center text-3xl md:text-5xl font-bold mb-6 md:mb-16">FAQ</h3>
            <div className="md:w-3/5 mx-auto faq">
              <Faq />
            </div>
          </div>
        </div>  

        <Footer />
        <Helmet><script type="text/javascript" src="https://gumroad.com/js/gumroad.js"></script></Helmet>
        <Helmet script={[{ type: 'text/javascript', innerHTML: `
                (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:2883118,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}] } />
        <Helmet><script src="https://cdn.paritydeals.com/banner.js" defer></script></Helmet>        
      </div>
    ),
    [showNavShadow, showVideo]
  )
}

export default IndexPage
