import { StaticQuery, Link, graphql } from "gatsby"
import React from "react"

const isPartiallyActive = ({
    isPartiallyCurrent
  }) => {
      const defaultClasses = 'pb-2 block hover:text-green-light font-medium';
    return isPartiallyCurrent
      ? { className: "text-primary-green"  + " " + defaultClasses }
      : { className: "text-zinc-600"  + " " + defaultClasses }
  }

const SidebarList = (data) => {
    // console.log('data', data.data);
    const list = data.data.map(item => {
        return <li className="mb-3 lg:mb-3" key={item.node.id}>
                <Link
                activeClassName="text-primary-green" 
                partiallyActive={true}
                getProps={isPartiallyActive}
                to={item.node.frontmatter.slug}>
                    {item.node.frontmatter.title}
                </Link>
            </li>;
    });

    return <ul>{list}</ul>
}

const Sidebar = () => {
    return (
        <StaticQuery
          query={graphql`
                query {
                    allMarkdownRemark(
                        sort: {order: ASC, fields: frontmatter___order},
                        filter: {
                            fileAbsolutePath: {regex: "/markdown-pages/"},
                            frontmatter: {order: {gt: 0}}
                        }
                    ) {
                        edges {
                            node {
                                id
                                frontmatter {
                                    title
                                    slug
                                    order
                                }
                            }
                        }
                    }
                }
          `}
          render={data => (
              <SidebarList data={data.allMarkdownRemark.edges}></SidebarList>
          )}
        />
      )
}

export default Sidebar
