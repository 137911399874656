import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Header from "./../components/header"
import Sidebar from "./../components/docs/sidebar"
import SEO from "./../components/seo"
import Footer from "../components/footer"

export default function Template({
  data,
}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const [showSideBar, setShowSideBar] = useState(false)

  const sidebarClasses = showSideBar ? 'md:block md:w-1/3 lg:w-1/4 lg:pr-8 pt-24' : 'hidden md:block md:w-1/3 lg:w-1/4 lg:pr-8 pt-24'

  return (
    <div>
        <SEO title={frontmatter.title} />
        <Header siteTitle="ACF to Custom Database Tables" toggleSideBar={()=>{setShowSideBar(!showSideBar)}} sideBarState={showSideBar} ></Header>
        <div className="container mx-auto px-6 pb-10">
            <div className="flex relative">
                <div className={sidebarClasses}>
                    <div className="doc-sticky fixed inset-0 bg-white z-10 md:sticky">
                        <div className="h-full overflow-y-auto scrolling-touch pt-20 md:pt-0 px-4 md:px-0">
                            <Sidebar/>
                        </div>
                    </div>
                </div>
                <div className="w-full md:w-2/3 lg:w-3/4 pt-24">
                    <div className="doc-content">
                        <h1 className="text-2xl md:text-3xl mb-6">{frontmatter.title}</h1>
                        <div
                            className="markdown prose"
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </div>
                </div>
            </div>
        </div>
        
        <div class="bg-gray-50">
            <div class="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span class="block">Upgrade to PRO</span>
                </h2>
                <div class="mt-8 flex justify-center">
                <div class="inline-flex rounded-md shadow">
                    <Link to="/pro/?ref=footer" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-primary-green hover:bg-green-light"> Get PRO version </Link>
                </div>
                </div>
            </div>
        </div>


        <Footer/>  
    </div>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
      }
    }
  }
`