import React, { Component } from 'react';
import { StaticQuery, graphql } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { Helmet } from "react-helmet"


class FaqUI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: []
        };

    }

    toggleQuestion(index){
        const opendIndex = [...this.state.opened];
        const stateIndex = opendIndex.indexOf(index);
        if(stateIndex === -1){
            opendIndex.push(index);
        }else {
            opendIndex.splice(stateIndex, 1);
        }
        this.setState({opened: opendIndex});

        trackCustomEvent({
            category: "lander",
            action: stateIndex === -1 ? 'faq_open' : 'faq_close',
            label: `faq_q_${index + 1}`
        })

    }

    render() {

        const faqSchema = <Helmet>
            <script type="application/ld+json">
                {`
                {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [${this.props.data.map((question, index) => {
                    return `{
                            "@type": "Question",
                            "name": "${question.node.frontmatter.title}",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "${question.node.html.replace(/"/g, '\\"')}"
                            }
                        }`;
                }).join(',')}]
                }
            `}
            </script>
        </Helmet>;

        const faqHtml = this.props.data.map((question, index)=>{
            return <div className="mb-5 shadow" key={index}>
                <h3 className="bg-white py-5 pl-5 pr-12 text-base relative font-semibold cursor-pointer flex items-center" onClick={() => { this.toggleQuestion(index); } }>
                    {question.node.frontmatter.title}
                    <span className={"absolute right-0 px-5 transition ease-in-out duration-500 " + (this.state.opened.includes(index) ? 'transform rotate-180' : '')}>
                        <svg viewBox="0 0 24 24" className="w-6 h-6">
                            <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
                        </svg>
                    </span>
                </h3>
                <div className={"px-5 pb-5 text-base leading-loose text-gray-700 " + (this.state.opened.includes(index) ? '' : 'hidden')} dangerouslySetInnerHTML={{ __html: question.node.html }}></div>
            </div>
        });

        return <>{faqHtml}{faqSchema}</>
    }
}

const Faq = () => {
    return (
        <StaticQuery
          query={graphql`
                query {
                    allMarkdownRemark(sort: {order: ASC, fields: frontmatter___order}, filter: {fileAbsolutePath: {regex: "/data/faq/"}}) {
                        edges {
                            node {
                                id
                                html
                                frontmatter {
                                    title
                                    order
                                }
                            }
                        }
                    }
                }
          `}
          render={data => (
              <FaqUI data={data.allMarkdownRemark.edges}></FaqUI>
          )}
        />
      )
}

export default Faq;