import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Faq from "../components/faq"
import Features from "../components/features"
import "../scss/lander.scss";

const FieldTypes = ()=>{
    const types = ['Text', 'Textarea', 'Number', 'Range', 'Email', 'URL', 'Password', 'WYSIWYG', 'oEmbed',
    'Image', 'File', 'Radio', 'True/False', 'Color Picker', 'Date Picker', 'Time Picker', 'Date/Time Picker',
    'Button Group', 'Gallery', 'Checkbox', 'Relationship', 'Google Map', 'Post Object', 'Page Link', 'Select', 'User', 'Link', 'Taxonomy', 'Repeater', 'Flexible Content', 'Group'];

    return types.map((type, index)=>{
        return <div className="w-1/5 px-3 mb-6" key={index}>
            <div className="shadow-xl py-4 bg-gray-200">{type}</div>
        </div>
    });
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="pt-16 intro">
        <div className="content">
            <div className="intro-wrap container mx-auto flex items-center p-6">
                <div className="w-1/2">
                    <h1 className="font-bold text-5xl">Store your<br/> ACF fields data in<br/> Custom Database Table</h1>
                    <a href="#" className="inline-block px-8 py-4 bg-yellow-500 rounded mt-4">Buy Now</a>
                </div>
                <div className="w-1/2 text-right">
                    <div className="video-card inline-block bg-white rounded shadow">s</div>
                </div>
            </div>
        </div>
        <div className="wave">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0099ff" fillOpacity="1" d="M0,64L60,106.7C120,149,240,235,360,229.3C480,224,600,128,720,106.7C840,85,960,139,1080,160C1200,181,1320,171,1380,165.3L1440,160L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
        </div>
    </div>
    <div className="mb-16">
        <div className="container mx-auto">
            <h3 className="text-center text-5xl text-gray-900 font-bold mb-16">Features</h3>
            <div className="flex flex-wrap text-center">
                <Features/>
            </div>
        </div>
    </div>
    <div className="bg-theme py-16 mb-16">
        <div className="container mx-auto">
            <h3 className="text-center text-5xl font-bold mb-16 text-white">Supported ACF Fields</h3>
            <div className="flex flex-wrap text-center">
                <FieldTypes/>
            </div>
        </div>
    </div>
    <div className="mb-16">
        <div className="container mx-auto">
            <h3 className="text-center text-5xl font-bold mb-16">FAQ</h3>
            <div className="w-3/5 mx-auto">
                <Faq/>
            </div>
        </div>
    </div>
  </Layout>
)

export default IndexPage
