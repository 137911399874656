import React, { Component } from 'react';
import Table from '../images/icons/table.svg';
import Tv from '../images/icons/tv.svg';
import Speed from '../images/icons/speed.svg';
import Code from '../images/icons/code.svg';
import Filter from '../images/icons/filter.svg';
import Log from '../images/icons/log.svg';

class features extends Component {
    constructor(props) {
        super(props);

        this.state = {
            featureList :[{
                title: 'Organised Data',
                desc: 'Store data in custom table',
                icon: Table
            },{
                title: 'Simple UI',
                desc: 'Create/update custom tables from interface',
                icon: Tv
            },{
                title: 'Speed',
                desc: ' Retrieve data efficiently',
                icon: Speed
            },{
                title: 'get_field support',
                desc: 'Use ACF native methods to retrive/update data',
                icon: Code
            },{
                title: 'Custom filters',
                desc: 'Extend features using custom filters',
                icon: Filter
            },{
                title: 'Logs',
                desc: 'Track table change history',
                icon: Log
            }],
            opened: null
        };

    }

    toggleQuestion(index){
        let newIndex = index;
        if(this.state.opened === index){
            newIndex = null;
        }
        this.setState({opened: newIndex});
    }

    render() {
        return this.state.featureList.map((feature, index)=>{
            return <div className="w-1/3 px-2 mb-16" key={index}>
            <img alt={ feature.title } className="inline-block mb-4 w-20" src={feature.icon}/>
            <h5 className="text-2xl mb-3">{ feature.title }</h5>
            <p className="text-sm text-gray-700">{ feature.desc }</p>
        </div>
        });;
    }
}

export default features;